import React from "react";
import { Button, Alert } from "antd";
import { useCookieConsent } from "../CookieConsentContext";

const CookieConsent = () => {
  const { cookiesAccepted, setCookiesAccepted } = useCookieConsent();

  // No mostrar el mensaje si el usuario ya aceptó o rechazó las cookies
  if (cookiesAccepted !== null) {
    return null;
  }

  return (
    <div style={{ position: "fixed", bottom: 0, width: "100%", zIndex: 1000 }}>
      <Alert
        message="Usamos cookies para mejorar tu experiencia. ¿Aceptas el uso de cookies?"
        type="info"
        action={
          <>
            <Button onClick={() => setCookiesAccepted(true)} type="primary">
              Aceptar
            </Button>
            <Button
              onClick={() => setCookiesAccepted(false)}
              style={{ marginLeft: 8 }}
            >
              Rechazar
            </Button>
          </>
        }
        closable={false}
      />
    </div>
  );
};

export default CookieConsent;
