// src/utils/route.js

import axios from "axios";

const ORS_API_KEY = "5b3ce3597851110001cf62482fd42f5bd98f4bada3f602a3fcc425fd"; // Asegúrate de que esta API key es válida y no está expirada

export const getRoute = async (origin, destination) => {
  const url =
    "https://api.openrouteservice.org/v2/directions/driving-car/geojson";
  const headers = {
    Authorization: ORS_API_KEY,
    "Content-Type": "application/json",
  };
  const data = {
    coordinates: [
      [origin.longitude, origin.latitude],
      [destination.longitude, destination.latitude],
    ],
  };

  try {
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (error) {
    console.error("Error al obtener la ruta:", error);
    return null;
  }
};
