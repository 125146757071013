// src/Components/common/OptionModal.js

import React, { useState, useMemo } from "react";
import { Modal, Radio, Form, Input } from "antd";
import PropTypes from "prop-types";

/**
 * Función helper para determinar el valor inicial de 'option'
 * basado en 'action' y 'parentAction'.
 */
const getInitialOption = (action, parentAction) => {
  if (action === "problema") {
    return parentAction === "iniciar" ? "punto_recogida" : "punto_destino";
  }
  return "recomendada";
};

const OptionModal = ({
  open,
  onCancel,
  onSubmit,
  title,
  action,
  parentAction,
}) => {
  const [form] = Form.useForm();
  const [option, setOption] = useState(getInitialOption(action, parentAction));

  // Definir las opciones basadas en la acción
  const options = useMemo(() => {
    if (action === "problema") {
      if (parentAction === "iniciar") {
        return [
          {
            value: "punto_recogida",
            label: "Ubicación No Válida para Iniciar",
          },
          {
            value: "sin_senal",
            label: "Sin Conectividad Para Iniciar",
          },
        ];
      } else if (parentAction === "finalizar") {
        return [
          {
            value: "punto_destino",
            label: "Ubicación No Válida para Finalizar",
          },
          {
            value: "sin_senal",
            label: "Sin Conectividad Para Finalizar",
          },
        ];
      }
    } else {
      return [
        {
          value: "recomendada",
          label: "Opción Recomendada (Ubicación Actual)",
        },
        ...(action === "iniciar"
          ? [
              {
                value: "punto_recogida",
                label: "Estoy en el Punto de Recogida",
              },
            ]
          : [{ value: "punto_destino", label: "En Punto de Destino" }]),
        {
          value: "sin_senal",
          label: "Sin Señal GPS/Internet",
        },
      ];
    }
  }, [action, parentAction]);

  // Función para reiniciar 'option' al cerrar el modal
  const resetOption = () => {
    setOption(getInitialOption(action, parentAction));
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onSubmit({ option: values.option, description: values.description });
        form.resetFields();
        resetOption(); // Resetear opción al cerrar
      })
      .catch((info) => {
        console.log("Validación fallida:", info);
      });
  };

  const onOptionChange = (e) => {
    const selectedOption = e.target.value;
    setOption(selectedOption);
    // Resetear campo de descripción si se selecciona 'recomendada'
    if (selectedOption === "recomendada") {
      form.resetFields(["description"]);
    }
  };

  /**
   * Función para determinar si se debe mostrar el campo de descripción
   */
  const shouldShowDescription = () =>
    (action !== "problema" && option !== "recomendada") ||
    (action === "problema" &&
      ["punto_recogida", "punto_destino", "sin_senal"].includes(option));

  return (
    <Modal
      open={open} // Usar 'open' en lugar de 'visible'
      title={title}
      onCancel={() => {
        form.resetFields();
        resetOption();
        onCancel();
      }}
      onOk={handleOk}
      okText="Confirmar"
      cancelText="Cancelar"
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ option: getInitialOption(action, parentAction) }}
      >
        <Form.Item
          name="option"
          label="Selecciona una opción"
          rules={[
            { required: true, message: "Por favor, selecciona una opción." },
          ]}
        >
          <Radio.Group onChange={onOptionChange}>
            {options.map((opt) => (
              <Radio key={opt.value} value={opt.value}>
                {opt.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        {shouldShowDescription() && (
          <Form.Item
            name="description"
            label="Descripción del Problema"
            rules={[
              {
                required: true,
                message: "Por favor, ingresa una descripción del problema.",
              },
            ]}
          >
            <Input.TextArea rows={4} placeholder="Describe el problema..." />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

OptionModal.propTypes = {
  open: PropTypes.bool.isRequired, // Cambiar 'visible' a 'open'
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.oneOf(["iniciar", "finalizar", "problema"]).isRequired, // Extend to include 'problema'
  parentAction: PropTypes.oneOf(["iniciar", "finalizar"]), // Needed for 'problema'
};

export default OptionModal;
