// src/components/Header.js

import React from "react";
import { Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useMediaQuery, useTheme } from "@mui/material";

const HeaderComponent = ({ toggleSidebar }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      className="header"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: isMobile ? "0 10px" : "0 20px",
        background: "#001529", // Fondo oscuro para el header
        color: "white",
        boxShadow: "0 2px 8px #f0f1f2",
        position: "sticky",
        top: 0,
        zIndex: 1,
        height: "64px", // Altura estándar de Ant Design Header
      }}
    >
      {/* Botón para alternar el Sidebar */}
      <Button
        type="text"
        onClick={toggleSidebar}
        icon={
          <MenuOutlined
            style={{ color: "white", fontSize: isMobile ? "18px" : "20px" }}
          />
        }
      />
    </div>
  );
};

HeaderComponent.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};

export default HeaderComponent;
