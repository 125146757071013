// src/common/MapComponent.js

import React, { useEffect, useState, useMemo } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Polyline,
  Popup,
} from "react-leaflet";
import L from "leaflet";
import PropTypes from "prop-types";
import { getRoute } from "../utils/route";
import { Spin } from "antd";
import "leaflet/dist/leaflet.css";
import { useCookieConsent } from "../CookieConsentContext"; // Importar el contexto de cookies
import driverIconImage from "../Assets/driver-icon.png";
import originIconImage from "../Assets/origin-icon.png";
import destinationIconImage from "../Assets/destination-icon.png";

// Configuración de iconos por defecto
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// Definir originIcon, destinationIcon y driverIcon fuera del componente
const originIcon = new L.Icon({
  iconUrl: originIconImage,
  iconSize: [30, 50],
  iconAnchor: [15, 50],
  popupAnchor: [0, -50],
});

const destinationIcon = new L.Icon({
  iconUrl: destinationIconImage,
  iconSize: [30, 50],
  iconAnchor: [15, 50],
  popupAnchor: [0, -50],
});

const driverIcon = new L.Icon({
  iconUrl: driverIconImage,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [0, -41],
});

const MapComponent = ({ origin, destination, driverLocation }) => {
  const [routeCoordinates, setRouteCoordinates] = useState([]);
  const [loading, setLoading] = useState(true);

  const { cookiesAccepted } = useCookieConsent(); // Obtener si el usuario ha aceptado las cookies

  const bounds = useMemo(() => {
    if (
      origin &&
      typeof origin.latitude === "number" &&
      typeof origin.longitude === "number" &&
      destination &&
      typeof destination.latitude === "number" &&
      typeof destination.longitude === "number"
    ) {
      return L.latLngBounds([
        [origin.latitude, origin.longitude],
        [destination.latitude, destination.longitude],
      ]);
    }
    return null;
  }, [origin, destination]);

  useEffect(() => {
    if (!origin || !destination) {
      console.warn("Origin or destination is missing");
      setLoading(false);
      return;
    }

    const fetchRoute = async () => {
      setLoading(true);
      try {
        const routeData = await getRoute(origin, destination);
        if (routeData && routeData.features.length > 0) {
          const coords = routeData.features[0].geometry.coordinates.map(
            (coord) => [coord[1], coord[0]]
          );
          console.log("Route coordinates:", coords);
          setRouteCoordinates(coords);
        } else {
          console.warn("No route data found");
        }
      } catch (error) {
        console.error("Error al obtener la ruta:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRoute();
  }, [origin, destination]);

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 999,
          }}
        >
          <Spin />
        </div>
      ) : (
        <MapContainer
          style={{ height: "100%", width: "100%" }}
          bounds={bounds}
          scrollWheelZoom={true}
        >
          {/* Cargar Google Maps solo si el usuario ha aceptado las cookies */}
          {cookiesAccepted ? (
            <TileLayer
              url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
              maxZoom={20}
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
              attribution='Map data &copy; <a href="https://www.google.com/maps">Google Maps</a>'
            />
          ) : (
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          )}

          {origin && (
            <Marker
              position={[origin.latitude, origin.longitude]}
              icon={originIcon}
            >
              <Popup>📍 Origen</Popup>
            </Marker>
          )}
          {destination && (
            <Marker
              position={[destination.latitude, destination.longitude]}
              icon={destinationIcon}
            >
              <Popup>📍 Destino</Popup>
            </Marker>
          )}
          {driverLocation && (
            <Marker
              position={[driverLocation.latitude, driverLocation.longitude]}
              icon={driverIcon}
            >
              <Popup>🚗 Conductor</Popup>
            </Marker>
          )}
          {routeCoordinates.length > 0 && (
            <Polyline positions={routeCoordinates} color="blue" />
          )}
        </MapContainer>
      )}
    </>
  );
};

MapComponent.propTypes = {
  origin: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }).isRequired,
  destination: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }).isRequired,
  driverLocation: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
};

export default MapComponent;
