// src/components/Sidebar.js

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Layout, Menu, Drawer, Button } from "antd";
import {
  TeamOutlined,
  LogoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { Box } from "@mui/material"; // Asegúrate de tener MUI instalado
import logo from "../Assets/img/logo.webp";
import { useSocket } from "../SocketContext";
import { useUser } from "../UserContext";

const { Sider } = Layout;

function Sidebar({ open, toggleDrawer }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { socket } = useSocket();
  const { user, setUser } = useUser();
  const [isMobile, setIsMobile] = useState(false);
  const version = "0.1.0 Beta";

  // Detectar si el dispositivo es móvil
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Define móvil como <= 768px
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleListItemClick = (route) => {
    navigate(`/modules/${route}`); // Navega a la ruta seleccionada bajo /modules/
    if (isMobile) {
      toggleDrawer(); // Cierra el Drawer solo en pantallas móviles
    }
  };

  const handleLogout = () => {
    if (user && socket) {
      socket.emit("logout", user.id);
      socket.disconnect();
    }
    setUser(null);
    localStorage.removeItem("user");
    navigate("/login");
  };

  const menuItems = [
    {
      label: "Mis Traslados",
      key: "Dashboard",
      icon: <TeamOutlined />,
      onClick: () => handleListItemClick("Dashboard"),
    },
    {
      label: "Configuración",
      key: "Settings",
      icon: <SettingOutlined />,
      onClick: () => handleListItemClick("Settings"),
    },
    // Añade más items según sea necesario
  ];

  const sidebarContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        backgroundColor: "#001529",
      }}
    >
      {/* Parte superior: Logo y Menú */}
      <Box>
        <Box
          sx={{
            padding: "10px",
            textAlign: "center",
            backgroundColor: "#001529",
          }}
        >
          <img
            src={logo}
            alt="Logo"
            style={{
              width: "80%", // Ajusta el tamaño del logo
              height: "auto",
            }}
          />
        </Box>

        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[location.pathname.split("/")[2]]} // Obtener el segmento de ruta correcto
          items={menuItems}
          style={{ borderRight: "none" }}
        />
      </Box>

      {/* Parte inferior: Cerrar sesión y Versión */}
      <Box
        sx={{
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Button
          type="text"
          icon={<LogoutOutlined style={{ color: "white", fontSize: "16px" }} />}
          onClick={handleLogout}
          style={{
            width: "100%",
            textAlign: "left",
            color: "white",
          }}
        >
          Cerrar sesión
        </Button>
        <Box
          sx={{
            textAlign: "center",
            color: "gray",
            fontSize: "0.9em",
          }}
        >
          Versión {version}
        </Box>
      </Box>
    </Box>
  );

  return isMobile ? (
    <Drawer
      title={<span style={{ color: "white" }}>Menú</span>}
      placement="left"
      onClose={toggleDrawer}
      open={open}
      style={{ backgroundColor: "#001529" }} // Fondo oscuro para el Drawer
      closeIcon={<span style={{ color: "white" }}>X</span>}
      drawerBodyStyle={{ padding: 0 }} // Usando drawerBodyStyle si es necesario
    >
      {sidebarContent}
    </Drawer>
  ) : (
    <Sider
      width={250}
      collapsible
      trigger={null} // Elimina el botón de toggle por defecto
      collapsed={!open}
      collapsedWidth={0} // Cambiado a 0 para ocultar completamente
      theme="dark"
      breakpoint="lg"
      style={{
        backgroundColor: "#001529",
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        transition: "all 0.2s", // Añadido para suavizar la transición
      }}
    >
      {sidebarContent}
    </Sider>
  );
}

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
};

export default Sidebar;
