import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginForm from "./Components/modules/Login/LoginForm";
import Modules from "./Components/modules/Modules";
import { SocketProvider } from "./SocketContext";
import { UserProvider } from "./UserContext";
import ProtectedRoute from "./ProtectedRoute";
import { ConfigProvider } from "antd";
import esES from "antd/lib/locale/es_ES";
import moment from "moment";
import "moment/locale/es";
import { TrasladosProvider } from "./TrasladosContext";
import { SettingsProvider } from "./SettingsContext";
import TrasladoMapView from "./Components/modules/Dashboard/TrasladoMapView";
import { CookieConsentProvider } from "./CookieConsentContext"; // Importar el nuevo contexto
import CookieConsent from "./Components/CookieConsent"; // Importar el componente

moment.locale("es");

function App() {
  return (
    <ConfigProvider locale={esES}>
      <UserProvider>
        <TrasladosProvider>
          <SettingsProvider>
            <SocketProvider>
              <CookieConsentProvider>
                {" "}
                {/* Envolver la aplicación con el nuevo contexto */}
                <Router>
                  <Routes>
                    <Route
                      path="/"
                      element={<Navigate replace to="/login" />}
                    />
                    <Route path="/login" element={<LoginForm />} />
                    <Route
                      path="/modules/*"
                      element={
                        <ProtectedRoute>
                          <Modules />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/modules/dashboard/traslado/:id"
                      element={
                        <ProtectedRoute>
                          <TrasladoMapView />
                        </ProtectedRoute>
                      }
                    />
                  </Routes>
                </Router>
                <CookieConsent />{" "}
                {/* Agregar el componente de consentimiento */}
              </CookieConsentProvider>
            </SocketProvider>
          </SettingsProvider>
        </TrasladosProvider>
      </UserProvider>
    </ConfigProvider>
  );
}

export default App;
