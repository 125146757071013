// src/index.js

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "antd/dist/reset.css";
import { ConfigProvider, notification } from "antd";
import esES from "antd/lib/locale/es_ES";
import moment from "moment";
import "moment/locale/es";
import "./index.css";
import "leaflet/dist/leaflet.css";

// Importa el archivo de Service Worker
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// Configurar moment para español (opcional)
moment.locale("es");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ConfigProvider locale={esES}>
      <App />
    </ConfigProvider>
  </React.StrictMode>
);

// Manejo de actualizaciones
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    notification.info({
      message: "Actualización disponible",
      description: "Hay una nueva versión de la aplicación disponible.",
      placement: "bottomRight",
    });

    // Opcional: Forzar la actualización inmediatamente
    if (registration?.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload();
    }
  },
});
