// src/Components/modules/Dashboard/Dashboard.js

import React, { useEffect, useState, useRef } from "react";
import { useTraslados } from "../../../TrasladosContext";
import { useSocket } from "../../../SocketContext";
import api from "../../../axiosConfig";
import { Button, Typography, message } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/es";
// import { calculateDistance } from "../../../utils/distance"; // Eliminado
import { useSettings } from "../../../SettingsContext";
import { useNavigate } from "react-router-dom"; // Importar useNavigate

import TrasladoList from "./TrasladoList";

const { Text } = Typography;

function Dashboard() {
  const { traslados, setTrasladosMerge, updateTraslado } = useTraslados();
  const [selectedDate, setSelectedDate] = useState(moment());
  const [loading, setLoading] = useState(false);

  // const [driverLocation, setDriverLocation] = useState(null); // Eliminado
  const { socket, socketConnected } = useSocket();

  const [geolocationError, setGeolocationError] = useState(null);

  const locationRef = useRef(null);
  const lastUpdateTimeRef = useRef(0);

  const { enableHighAccuracy } = useSettings();

  const navigate = useNavigate(); // Inicializar useNavigate

  useEffect(() => {
    fetchTraslados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    let watchId;
    let intervalId;

    if (socketConnected) {
      if ("geolocation" in navigator) {
        watchId = navigator.geolocation.watchPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            const currentTime = Date.now();

            if (currentTime - lastUpdateTimeRef.current > 5000) {
              lastUpdateTimeRef.current = currentTime;
              // setDriverLocation({ latitude, longitude }); // Eliminado
              setGeolocationError(null);
              locationRef.current = { latitude, longitude };
            }
          },
          (error) => {
            console.error("Error al obtener la ubicación:", error);
            setGeolocationError(
              "Error al obtener la ubicación. Por favor, permite el acceso a la ubicación."
            );
            message.error(
              "Error al obtener la ubicación. Por favor, permite el acceso a la ubicación."
            );
          },
          {
            enableHighAccuracy: enableHighAccuracy,
            maximumAge: 0,
            timeout: 10000,
          }
        );

        intervalId = setInterval(() => {
          if (socket && locationRef.current) {
            let { latitude, longitude } = locationRef.current;

            if (
              typeof latitude === "number" &&
              typeof longitude === "number" &&
              !isNaN(latitude) &&
              !isNaN(longitude)
            ) {
              latitude = parseFloat(latitude.toFixed(6));
              longitude = parseFloat(longitude.toFixed(6));

              socket.emit("driver_location_update", { latitude, longitude });
            } else {
              console.error(
                "Coordenadas inválidas en locationRef.current:",
                locationRef.current
              );
            }
          }
        }, 60000);
      } else {
        message.error(
          "La geolocalización no está disponible en este dispositivo."
        );
      }
    }

    return () => {
      if (watchId && navigator.geolocation) {
        navigator.geolocation.clearWatch(watchId);
      }
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [socket, socketConnected, enableHighAccuracy]);

  const fetchTraslados = async () => {
    setLoading(true);
    try {
      const response = await api.get("/traslados/mis-traslados", {
        params: {
          fecha: selectedDate.format("YYYY-MM-DD"),
        },
      });
      setTrasladosMerge(response.data);
    } catch (error) {
      console.error("Error al cargar los traslados:", error);
      message.error("Error al cargar los traslados");
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (e) => {
    setSelectedDate(moment(e.target.value));
  };

  const handlePrevDay = () => {
    setSelectedDate((prevDate) => prevDate.clone().subtract(1, "day"));
  };

  const handleNextDay = () => {
    setSelectedDate((prevDate) => prevDate.clone().add(1, "day"));
  };

  // Modificar la función para navegar a la nueva ruta en lugar de abrir el diálogo
  const handleTrasladoClick = async (traslado) => {
    // Cambiar estado a "Enterado" cuando el conductor vea el traslado
    if (traslado.status === "Asignado") {
      try {
        await api.put(`/traslados/enterado/${traslado.id}`);
        updateTraslado({ ...traslado, status: "Enterado" });
      } catch (error) {
        console.error("Error al actualizar el traslado:", error);
        message.error("Error al actualizar el traslado");
      }
    }
    // Navegar a la página de detalle del traslado
    navigate(`/modules/dashboard/traslado/${traslado.id}`);
  };

  // Eliminamos las funciones handleAceptarTraslado, handleEnCamino, etc., de aquí
  // Estas serán manejadas en TrasladoMapView

  const getStatusColor = (status) => {
    const statusColors = {
      Cancelado: "#b10e1a",
      Finalizado: "#4d4d4d",
      Fallido: "#4a2c04",
      Asignado: "#1f7362",
      "Pre-Asignado": "#0c27a0",
      Programado: "#6c06b5",
      Enterado: "#0c5b07",
      Iniciado: "#fa7114",
      Aceptado: "#088A08",
      "En Camino": "#FFBF00",
      Esperando: "#a44706",
      default: "#b0b500",
    };
    return statusColors[status] || statusColors.default;
  };

  // Eliminamos renderActionButton
  // const renderActionButton = (traslado) => { ... }

  const filteredTraslados = traslados.filter((traslado) =>
    moment(traslado.fecha).isSame(selectedDate, "day")
  );

  return (
    <div style={{ padding: 0 }}>
      {/* Encabezado */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 10,
          position: "sticky",
          top: 60, // altura del header principal
          backgroundColor: "#fff",
          zIndex: 100,
          borderBottom: "1px solid #d9d9d9",
        }}
      >
        <Button icon={<LeftOutlined />} onClick={handlePrevDay} />
        <input
          type="date"
          value={selectedDate.format("YYYY-MM-DD")}
          onChange={handleDateChange}
          style={{
            border: "none",
            borderBottom: "1px solid #d9d9d9",
            padding: 5,
            fontSize: 16,
            width: 140,
            textAlign: "center",
          }}
        />
        <Button icon={<RightOutlined />} onClick={handleNextDay} />
      </div>

      {/* Mostrar error de geolocalización si existe */}
      {geolocationError && (
        <div style={{ padding: 10, textAlign: "center" }}>
          <Text type="danger">{geolocationError}</Text>
        </div>
      )}

      {/* Lista de traslados filtrados */}
      <TrasladoList
        traslados={filteredTraslados}
        loading={loading}
        onSelect={handleTrasladoClick} // Al seleccionar, navegará a la nueva ruta
        // Eliminamos renderActionButton
        getStatusColor={getStatusColor}
      />
    </div>
  );
}

export default Dashboard;
