// src/Components/modules/Settings/Settings.js
import React from "react";
import { Switch, Typography, Card, Form, Tooltip, Space, Divider } from "antd";
import {
  SettingOutlined,
  ThunderboltOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useSettings } from "../../../SettingsContext";

const { Title, Text } = Typography;

function Settings() {
  const { enableHighAccuracy, setEnableHighAccuracy } = useSettings();

  const handleToggle = (checked) => {
    setEnableHighAccuracy(checked);
  };

  return (
    <div
      style={{ padding: 20, backgroundColor: "#f0f2f5", minHeight: "100vh" }}
    >
      <Card
        title={
          <Space align="center">
            <SettingOutlined style={{ fontSize: "24px" }} />
            <Title level={3} style={{ margin: 0 }}>
              Configuraciones
            </Title>
          </Space>
        }
        bordered={false}
        style={{
          maxWidth: 600,
          margin: "auto",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          borderRadius: 10,
          backgroundColor: "#ffffff",
        }}
      >
        <Form layout="vertical">
          <Form.Item
            label="Optimizar la Precisión de la Ubicación"
            style={{ marginBottom: 30 }}
          >
            <Space align="center">
              {/* Icono Representando Alta Precisión */}
              <Tooltip title="Alta Precisión: Mayor exactitud, mayor consumo de batería">
                <ThunderboltOutlined
                  style={{
                    fontSize: "20px",
                    color: enableHighAccuracy ? "#52c41a" : "#eb2f96",
                  }}
                />
              </Tooltip>

              {/* Switch con Etiquetas Claras */}
              <Switch
                checked={enableHighAccuracy}
                onChange={handleToggle}
                checkedChildren="Alta Precisión"
                unCheckedChildren="Baja Precisión"
                style={{ width: 120 }}
              />

              {/* Icono Representando Baja Precisión */}
              <Tooltip title="Baja Precisión: Menor exactitud, menor consumo de batería">
                <SyncOutlined
                  style={{
                    fontSize: "20px",
                    color: !enableHighAccuracy ? "#faad14" : "#d9d9d9",
                  }}
                />
              </Tooltip>
            </Space>
          </Form.Item>
          <Divider />
          <Form.Item>
            <Text type="secondary">
              Cuando está activado, la aplicación usará una mayor precisión para
              la geolocalización, lo que puede consumir más batería.
            </Text>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default Settings;
