// src/components/Modules.js

import React, { useState, useEffect } from "react";
import { Layout, theme } from "antd";
import { Routes, Route } from "react-router-dom";
import Sidebar from "../Sidebar";
import HeaderComponent from "../Header";
import Footer from "../Footer";
import "./Modules.css"; // Importa el CSS

// Importa tus componentes de rutas aquí
import Dashboard from "./Dashboard/Dashboard";
import Settings from "./Settings/Settings"; // Importar el componente de Configuración

import { useMediaQuery, useTheme } from "@mui/material";

const { Content } = Layout;

function Modules() {
  const themeMUI = useTheme();
  const isMobile = useMediaQuery(themeMUI.breakpoints.down("sm"));

  // Cambia el estado inicial dependiendo si es un dispositivo móvil
  const [open, setOpen] = useState(!isMobile);

  useEffect(() => {
    // Actualiza el estado cuando el tamaño de la pantalla cambie
    setOpen(!isMobile);
  }, [isMobile]);

  const toggleDrawer = () => setOpen(!open);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar open={open} toggleDrawer={toggleDrawer} />
      <Layout
        className="site-layout custom-layout"
        style={{
          marginLeft: open && !isMobile ? 250 : 0,
          transition: "all 0.2s",
        }}
      >
        <HeaderComponent toggleSidebar={toggleDrawer} />
        <Content style={{ margin: "0 16px" }}>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Routes>
              <Route path="Dashboard" element={<Dashboard />} />
              <Route path="Settings" element={<Settings />} />{" "}
              {/* Nueva Ruta */}
              <Route path="/" element={<div>Seleccione una opción</div>} />
            </Routes>
          </div>
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
}

export default Modules;
