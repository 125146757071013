// src/Components/modules/Auth/LoginForm.js

import React, { useState } from "react";
import api from "../../../axiosConfig"; // Importar la instancia personalizada de axios
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../UserContext";
import {
  Container,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Alert,
  Snackbar,
  useMediaQuery,
  CircularProgress,
  Fade, // Asegúrate de importar Fade si lo usas
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Lock from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import logo from "../../../Assets/img/logo.webp"; // Asegúrate de que la ruta es correcta

function LoginForm() {
  // Estados para manejar los inputs y la visibilidad de la contraseña
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // Estados para manejo de errores y carga
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Estados para las notificaciones de Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // Hooks de navegación y contexto de usuario
  const navigate = useNavigate();
  const { loginUser } = useUser();

  // Hooks de tema y media query para responsividad
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Función para manejar el inicio de sesión
  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setLoading(true);

    try {
      const response = await api.post("/auth/conductor/login", {
        identifier,
        password,
      });

      const {
        accessToken,
        refreshToken,
        user,
        message: serverMessage,
      } = response.data;

      // Almacenar los tokens y usuario en UserContext
      loginUser(user, accessToken, refreshToken);

      // Navegar al dashboard o módulo principal
      navigate("/modules");

      // Mostrar mensaje de éxito en Snackbar
      setSnackbarMessage(serverMessage || "Inicio de sesión exitoso.");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error al iniciar sesión:", error);

      // Determinar el mensaje de error
      if (error.response?.data?.message) {
        setErrorMessage(error.response.data.message);
        setSnackbarMessage(error.response.data.message);
      } else if (error.request) {
        setErrorMessage("No se recibió respuesta del servidor.");
        setSnackbarMessage("No se recibió respuesta del servidor.");
      } else {
        setErrorMessage("Error al iniciar sesión.");
        setSnackbarMessage("Error al iniciar sesión.");
      }

      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  // Función para alternar la visibilidad de la contraseña
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(to bottom right, #0599ee, #0a2e5c)",
        padding: isMobile ? 2 : 0,
      }}
    >
      <Fade in>
        <Box
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            boxShadow: 6,
            borderRadius: 4,
            p: isMobile ? 4 : 6,
            textAlign: "center",
            transition: "transform 0.3s",
            "&:hover": {
              transform: "scale(1.02)",
            },
          }}
        >
          {/* Logo */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: isMobile ? 3 : 4,
            }}
          >
            <Box
              component="img"
              src={logo}
              alt="Logo"
              sx={{
                width: isMobile ? 240 : 300, // Aumenta el tamaño del logo
                height: "auto",
                objectFit: "contain",
              }}
            />
          </Box>

          {/* Formulario */}
          <Box component="form" onSubmit={handleLogin} noValidate>
            {/* Campo de Identificador */}
            <TextField
              margin="normal"
              required
              fullWidth
              id="identifier"
              label="Email o Número de Documento"
              name="identifier"
              autoComplete="identifier"
              autoFocus
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle color="action" />
                  </InputAdornment>
                ),
              }}
              sx={{
                bgcolor: "#f0f4f8",
                borderRadius: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#0a2e5c",
                  },
                  "&:hover fieldset": {
                    borderColor: "#0599ee",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0599ee",
                  },
                },
              }}
            />

            {/* Campo de Contraseña */}
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock color="action" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPassword
                          ? "Ocultar contraseña"
                          : "Mostrar contraseña"
                      }
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                bgcolor: "#f0f4f8",
                borderRadius: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#0a2e5c",
                  },
                  "&:hover fieldset": {
                    borderColor: "#0599ee",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0599ee",
                  },
                },
              }}
            />

            {/* Mensaje de Error */}
            {errorMessage && (
              <Alert severity="error" sx={{ mt: 2, borderRadius: 2 }}>
                {errorMessage}
              </Alert>
            )}

            {/* Botón de Envío */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              sx={{
                mt: 4,
                mb: 2,
                py: 1.5,
                borderRadius: 3,
                fontWeight: "bold",
                backgroundColor: "#0a2e5c",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
                "&:hover": {
                  backgroundColor: "#0599ee",
                },
                transition: "background-color 0.3s, box-shadow 0.3s",
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Iniciar Sesión"
              )}
            </Button>

            {/* Enlaces Adicionales */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 2,
              }}
            >
              <Button
                variant="text"
                size="small"
                sx={{ color: "#0a2e5c", textTransform: "none" }}
                onClick={() => navigate("/forgot-password")}
              >
                ¿Olvidaste tu contraseña?
              </Button>
              <Button
                variant="text"
                size="small"
                sx={{ color: "#0a2e5c", textTransform: "none" }}
                onClick={() => navigate("/register")}
              >
                Crear cuenta
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>

      {/* Snackbar para Notificaciones */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        TransitionComponent={Fade}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%", borderRadius: 2 }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default LoginForm;
