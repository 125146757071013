// src/Components/modules/Dashboard/TrasladoList.js

import React from "react";
import PropTypes from "prop-types";
import { List } from "antd";
import TrasladoItem from "./TrasladoItem";

const TrasladoList = ({ traslados, loading, onSelect, getStatusColor }) => {
  return (
    <List
      loading={loading}
      dataSource={traslados}
      renderItem={(traslado) => {
        const statusColor = getStatusColor(traslado.status);
        return (
          <List.Item
            key={traslado.id}
            style={{
              width: "100%",
              padding: "8px", // Asegurar buen espaciado
              display: "flex",
              justifyContent: "center",
              marginBottom: "16px", // Añadir espaciado entre las tarjetas
            }}
          >
            <TrasladoItem
              traslado={traslado}
              onSelect={onSelect}
              statusColor={statusColor}
            />
          </List.Item>
        );
      }}
    />
  );
};

TrasladoList.propTypes = {
  traslados: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      // Eliminamos status_secondary
      fecha: PropTypes.string.isRequired,
      hora_recogida: PropTypes.string.isRequired,
      origen_direccion: PropTypes.string.isRequired,
      origen_ciudad: PropTypes.string.isRequired,
      destino_direccion: PropTypes.string.isRequired,
      destino_ciudad: PropTypes.string.isRequired,
      nombre_paciente: PropTypes.string.isRequired,
      inicio_status: PropTypes.string, // Agregado
      fin_status: PropTypes.string, // Agregado
    }).isRequired
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  getStatusColor: PropTypes.func.isRequired,
};

export default TrasladoList;
