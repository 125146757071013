import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import PropTypes from "prop-types";

// Crear el contexto
const CookieConsentContext = createContext();

// Proveedor del contexto
export const CookieConsentProvider = ({ children }) => {
  const [cookiesAccepted, setCookiesAccepted] = useState(null); // null significa que aún no ha sido decidido

  // Cargar el estado desde localStorage
  useEffect(() => {
    const storedConsent = localStorage.getItem("cookiesAccepted");
    if (storedConsent !== null) {
      setCookiesAccepted(JSON.parse(storedConsent));
    }
  }, []);

  // Guardar la preferencia del usuario en localStorage
  useEffect(() => {
    if (cookiesAccepted !== null) {
      localStorage.setItem("cookiesAccepted", JSON.stringify(cookiesAccepted));
    }
  }, [cookiesAccepted]);

  // Memoizar el valor del contexto
  const contextValue = useMemo(
    () => ({ cookiesAccepted, setCookiesAccepted }),
    [cookiesAccepted]
  );

  return (
    <CookieConsentContext.Provider value={contextValue}>
      {children}
    </CookieConsentContext.Provider>
  );
};

// Validar las props del proveedor
CookieConsentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Hook para usar el contexto
export const useCookieConsent = () => useContext(CookieConsentContext);
