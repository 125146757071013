import React from "react";

function FooterComponent() {
  return (
    <div
      className="footer"
      style={{
        display: "flex",
        justifyContent: "center", // Centrar el contenido
        alignItems: "center",
        padding: "10px 20px",
        width: "100%",
        backgroundColor: "#001529",
        color: "white",
        position: "sticky", // Fijar el footer al fondo de la pantalla visible
        bottom: 0, // Posicionar al fondo
        zIndex: 1000,
      }}
    >
      <span>Copyright © 2024 Mogotax SAS. Todos los derechos reservados.</span>
    </div>
  );
}

export default FooterComponent;
