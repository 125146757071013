// src/TrasladosContext.js

import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import PropTypes from "prop-types";

const TrasladosContext = createContext();

export const useTraslados = () => {
  return useContext(TrasladosContext);
};

export const TrasladosProvider = ({ children }) => {
  // Desestructuración siguiendo la convención estándar
  const [traslados, setTraslados] = useState([]);

  // Función para reemplazar todos los traslados (renombrada)
  const replaceTraslados = useCallback((nuevosTraslados) => {
    setTraslados(nuevosTraslados);
  }, []);

  // Función para fusionar traslados sin duplicados
  const setTrasladosMerge = useCallback((nuevosTraslados) => {
    setTraslados((prevTraslados) => {
      const trasladosMap = new Map();
      // Añadir los traslados previos al mapa
      prevTraslados.forEach((traslado) => {
        trasladosMap.set(traslado.id, traslado);
      });
      // Añadir o actualizar los nuevos traslados en el mapa
      nuevosTraslados.forEach((traslado) => {
        trasladosMap.set(traslado.id, traslado);
      });
      // Convertir el mapa de vuelta a una lista
      return Array.from(trasladosMap.values());
    });
  }, []);

  const addTraslado = useCallback((nuevoTraslado) => {
    setTraslados((prevTraslados) => {
      const existe = prevTraslados.some((t) => t.id === nuevoTraslado.id);
      if (!existe) {
        return [...prevTraslados, nuevoTraslado];
      } else {
        return prevTraslados.map((t) =>
          t.id === nuevoTraslado.id ? nuevoTraslado : t
        );
      }
    });
  }, []);

  const updateTraslado = useCallback((trasladoActualizado) => {
    setTraslados((prevTraslados) =>
      prevTraslados.map((traslado) =>
        traslado.id === trasladoActualizado.id ? trasladoActualizado : traslado
      )
    );
  }, []);

  const removeTraslado = useCallback((trasladoId) => {
    setTraslados((prevTraslados) =>
      prevTraslados.filter((traslado) => traslado.id !== trasladoId)
    );
  }, []);

  // Envolver el valor en useMemo para evitar recrear el objeto en cada render
  const value = useMemo(
    () => ({
      traslados,
      replaceTraslados, // Renombrado
      setTrasladosMerge, // Mantiene el nombre
      addTraslado,
      updateTraslado,
      removeTraslado,
    }),
    [
      traslados,
      replaceTraslados,
      setTrasladosMerge,
      addTraslado,
      updateTraslado,
      removeTraslado,
    ]
  );

  return (
    <TrasladosContext.Provider value={value}>
      {children}
    </TrasladosContext.Provider>
  );
};

TrasladosProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
