// src/serviceWorkerRegistration.js

// Este código se basa en el archivo original de create-react-app

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] es la dirección localhost de IPv6.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 se considera localhost para IPv4.
    (function () {
      const localhostRegex = /^127(?:\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)){3}$/;
      return localhostRegex.exec(window.location.hostname);
    })()
);

export function register(config) {
  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    // El constructor URL está disponible en todos los navegadores que soportan SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Nuestro service worker no funcionará si PUBLIC_URL está en un origen diferente
      return;
    }

    window.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // Esto es ejecutado en localhost. Comprobar si existe un service worker o no.
        checkValidServiceWorker(swUrl, config);

        // Agrega más logs para los desarrolladores.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            "Esta aplicación está siendo servida en caché por un service worker."
          );
        });
      } else {
        // No es localhost. Registra el service worker.
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker === null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              // En este punto, el antiguo contenido en caché ha sido actualizado.
              console.log("Nueva actualización disponible.");
              if (config?.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // En este punto, todos ha sido precacheado.
              console.log("Contenido precacheado para uso sin conexión.");
              if (config?.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error("Error durante el registro del service worker:", error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  // Comprueba si el service worker puede ser encontrado.
  fetch(swUrl, {
    headers: { "Service-Worker": "script" },
  })
    .then((response) => {
      // Asegúrate de que el service worker existe y que realmente estamos obteniendo un archivo JS.
      const contentType = response.headers.get("content-type");
      if (
        response.status === 404 ||
        contentType?.indexOf("javascript") === -1
      ) {
        // No se encontró ningún service worker. Probablemente una aplicación diferente.
        navigator.serviceWorker.ready
          .then((registration) => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          })
          .catch((error) => {
            console.error("Error al desregistrar el service worker:", error);
          });
      } else {
        // Service worker encontrado. Procede normalmente.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        "No se pudo encontrar el service worker. No hay conexión a Internet."
      );
    });
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
