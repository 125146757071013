// services/trasladosServices/iniciarTrasladoService.js
// ... (tu código existente)

// TrasladoItem.js
import React from "react";
import { Card, Typography, Space, Tag } from "antd";
import { ClockCircleOutlined, EnvironmentOutlined } from "@ant-design/icons";
import moment from "moment";
import PropTypes from "prop-types";

const { Text } = Typography;

const TrasladoItem = ({ traslado, onSelect, statusColor }) => {
  // Función para determinar el color de las etiquetas
  const getStatusColor = (status) => {
    const statusColors = {
      Cancelado: "#b10e1a",
      Finalizado: "#4d4d4d",
      Fallido: "#4a2c04",
      Asignado: "#1f7362",
      "Pre-Asignado": "#0c27a0",
      Programado: "#6c06b5",
      Enterado: "#0c5b07",
      Iniciado: "#fa7114",
      Aceptado: "#088A08",
      "En Camino": "#FFBF00",
      Esperando: "#a44706",
      "Inicio Exitosamente": "#52c41a", // Verde para inicio exitoso
      "Finalizo Exitosamente": "#52c41a", // Verde para finalización exitosa
      default: "#b0b500",
    };
    return statusColors[status] || statusColors.default;
  };

  return (
    <Card
      onClick={() => onSelect(traslado)}
      style={{
        marginBottom: 16,
        padding: "0",
        borderRadius: 12,
        overflow: "hidden",
        cursor: "pointer",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        border: "none",
        width: "100%",
        maxWidth: "90vw",
        margin: "0 auto",
        backgroundColor: "#f9f9f9",
      }}
      hoverable
    >
      {/* Encabezado con color basado en el estado */}
      <div
        style={{
          backgroundColor: statusColor,
          padding: "10px 12px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
        }}
      >
        <Text strong style={{ color: "#fff", fontSize: "1rem" }}>
          CS-{traslado.id}
        </Text>
        <div>
          {/* Reemplazamos el Text por un Tag para mostrar el estado */}
          <Tag
            color={getStatusColor(traslado.status)}
            style={{
              fontSize: "0.9rem",
              border: "1px solid #fff", // Borde blanco
              color: "#fff", // Texto blanco para mejor contraste con el fondo
              padding: "2px 8px", // Añadir padding para un mejor tamaño de la etiqueta
              borderRadius: "8px", // Redondear los bordes
            }}
          >
            {traslado.status}
          </Tag>
        </div>
      </div>

      {/* Cuerpo de la tarjeta */}
      <div style={{ padding: "10px 12px" }}>
        <Space direction="vertical" size="small">
          <Text style={{ fontSize: "0.9rem" }}>
            <ClockCircleOutlined /> Fecha:{" "}
            {moment(traslado.fecha).format("DD/MM/YYYY")}
          </Text>
          <Text style={{ fontSize: "0.9rem" }}>
            <ClockCircleOutlined /> Hora Recogida:{" "}
            {traslado.hora_recogida.slice(0, 5)}
          </Text>
          <Text style={{ fontSize: "0.9rem" }}>
            <EnvironmentOutlined /> De: {traslado.origen_direccion} -{" "}
            {traslado.origen_ciudad}
          </Text>
          <Text style={{ fontSize: "0.9rem" }}>
            <EnvironmentOutlined /> A: {traslado.destino_direccion} -{" "}
            {traslado.destino_ciudad}
          </Text>
          <Text style={{ fontSize: "0.9rem", fontWeight: 500 }}>
            Paciente: {traslado.nombre_paciente}
          </Text>

          {/* Contenedor para las etiquetas de estado */}
          <div style={{ marginTop: 10 }}>
            {traslado.inicio_status && (
              <Tag
                color={getStatusColor(traslado.inicio_status)}
                style={{
                  marginBottom: "8px", // Añadir margen inferior entre las etiquetas
                  fontSize: "0.9rem",
                  border: "1px solid #fff", // Borde blanco
                  color: "#fff", // Texto blanco
                  padding: "2px 8px", // Tamaño de la etiqueta
                  borderRadius: "8px", // Borde redondeado
                }}
              >
                {traslado.inicio_status}
              </Tag>
            )}
            {traslado.fin_status && (
              <Tag
                color={getStatusColor(traslado.fin_status)}
                style={{
                  fontSize: "0.9rem",
                  border: "1px solid #fff", // Borde blanco
                  color: "#fff", // Texto blanco
                  padding: "2px 8px", // Tamaño de la etiqueta
                  borderRadius: "8px", // Borde redondeado
                }}
              >
                {traslado.fin_status}
              </Tag>
            )}
          </div>
        </Space>
      </div>
    </Card>
  );
};

TrasladoItem.propTypes = {
  traslado: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    fecha: PropTypes.string.isRequired,
    hora_recogida: PropTypes.string.isRequired,
    origen_direccion: PropTypes.string.isRequired,
    origen_ciudad: PropTypes.string.isRequired,
    destino_direccion: PropTypes.string.isRequired,
    destino_ciudad: PropTypes.string.isRequired,
    nombre_paciente: PropTypes.string.isRequired,
    inicio_status: PropTypes.string, // Agregado
    fin_status: PropTypes.string, // Agregado
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  statusColor: PropTypes.string.isRequired,
};

export default TrasladoItem;
