// src/Components/modules/Dashboard/TrasladoMapView.js

import React, { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTraslados } from "../../../TrasladosContext";
import { useSocket } from "../../../SocketContext";
import api from "../../../axiosConfig";
import { Button, Typography, message, Spin, Card, Alert, Tag } from "antd";
import {
  LeftOutlined,
  CheckCircleOutlined,
  CarOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  FlagOutlined,
  EnvironmentOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import MapComponent from "../../../common/MapComponent";
import { calculateDistance } from "../../../utils/distance";
import OptionModal from "../../../common/OptionModal";

const { Text } = Typography;

const TrasladoMapView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { traslados, updateTraslado } = useTraslados();
  const [traslado, setTraslado] = useState(null);
  const [loading, setLoading] = useState(true);
  const { socketConnected } = useSocket();
  const [driverLocation, setDriverLocation] = useState(null);
  const [geolocationError, setGeolocationError] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);

  // Estados para el modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentAction, setCurrentAction] = useState(null); // 'iniciar' o 'finalizar'

  useEffect(() => {
    const fetchTraslado = async () => {
      const foundTraslado = traslados.find(
        (t) => t.id.toString() === id.toString()
      );

      if (!foundTraslado) {
        setLoading(true);
        try {
          const response = await api.get(`/traslados/${id}`);
          setTraslado(response.data);
        } catch (error) {
          console.error("Error al obtener el traslado:", error);
          message.error("Error al obtener los detalles del traslado.");
        } finally {
          setLoading(false);
        }
      } else {
        setTraslado(foundTraslado);
        setLoading(false);
      }
    };

    fetchTraslado();
  }, [id, traslados]);

  useEffect(() => {
    if (!traslado) return;

    let watchId;

    if (socketConnected && "geolocation" in navigator) {
      watchId = navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setDriverLocation({ latitude, longitude });
          setGeolocationError(null);
        },
        (error) => {
          console.error("Error al obtener la ubicación:", error);
          setGeolocationError(
            "Error al obtener la ubicación. Por favor, permite el acceso a la ubicación."
          );
          message.error(
            "Error al obtener la ubicación. Por favor, permite el acceso a la ubicación."
          );
        },
        {
          enableHighAccuracy: true,
          maximumAge: 0,
          timeout: 10000,
        }
      );
    }

    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [socketConnected, traslado]);

  const handleBack = () => {
    navigate("/modules/dashboard");
  };

  // Función para obtener el color basado en el estado
  const getStatusColor = (status) => {
    const statusColors = {
      Cancelado: "error",
      Finalizado: "default",
      Fallido: "error",
      Asignado: "geekblue",
      "Pre-Asignado": "blue",
      Programado: "purple",
      Enterado: "success",
      Iniciado: "warning",
      Aceptado: "success",
      "En Camino": "gold",
      Esperando: "warning",
      default: "lime",
    };
    return statusColors[status] || statusColors.default;
  };

  // Función genérica para manejar acciones sin opciones
  const handleAction = async (endpoint, successMessage) => {
    setActionLoading(true);
    try {
      const response = await api.put(endpoint);
      updateTraslado(response.data.traslado);
      setTraslado(response.data.traslado);
      message.success(successMessage);
    } catch (error) {
      console.error("Error al realizar la acción:", error);
      message.error(
        error.response?.data?.error || "Error al realizar la acción."
      );
    } finally {
      setActionLoading(false);
    }
  };

  // Funciones específicas para acciones sin opciones
  const handleAceptarTraslado = () => {
    handleAction(
      `/traslados/aceptar/${traslado.id}`,
      "Has aceptado el traslado."
    );
  };

  const handleEnCamino = () => {
    handleAction(
      `/traslados/en-camino/${traslado.id}`,
      "Has marcado el traslado como En Camino."
    );
  };

  const handleEsperando = () => {
    handleAction(
      `/traslados/esperando/${traslado.id}`,
      "Has marcado el traslado como Esperando."
    );
  };

  // Funciones para iniciar y finalizar traslado con modal
  const handleIniciarTraslado = async (
    option = "recomendada",
    description = null
  ) => {
    await handleInicioTraslado(option, description);
  };

  const handleFinalizarTraslado = async (
    option = "recomendada",
    description = null
  ) => {
    await handleFinTraslado(option, description);
  };

  const handleProblemTraslado = (action) => {
    setCurrentAction(action); // 'iniciar' o 'finalizar'
    setIsModalVisible(true);
  };

  // Función para determinar el título del modal
  const getModalTitle = () => {
    if (currentAction === "iniciar")
      return "Reportar un problema al iniciar el traslado";
    if (currentAction === "finalizar")
      return "Reportar un problema al finalizar el traslado";
    return "Acción";
  };

  // Función para manejar la confirmación del modal
  const handleModalSubmit = async ({ option, description }) => {
    setIsModalVisible(false);
    setActionLoading(true);

    if (currentAction === "iniciar") {
      await handleInicioTraslado(option, description);
    } else if (currentAction === "finalizar") {
      await handleFinTraslado(option, description);
    }

    setCurrentAction(null);
  };

  // Funciones para enviar datos al servidor
  const handleInicioTraslado = async (option, description) => {
    if (option === "recomendada") {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const distance = calculateDistance(
            latitude,
            longitude,
            traslado.origen_coordenadas.coordinates[1],
            traslado.origen_coordenadas.coordinates[0]
          );

          if (distance <= 100) {
            await enviarIniciarTraslado(option, { latitude, longitude }, null);
          } else {
            message.warning(
              "Debes estar cerca del punto de recogida para iniciar el traslado."
            );
            setActionLoading(false);
          }
        },
        (error) => {
          console.error("Error al obtener la ubicación:", error);
          message.error("No se pudo obtener la ubicación actual.");
          setActionLoading(false);
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      // Opción "punto_recogida" o "sin_senal"
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          await enviarIniciarTraslado(
            option,
            { latitude, longitude },
            description
          );
        },
        (error) => {
          console.error("Error al obtener la ubicación:", error);
          message.error("No se pudo obtener la ubicación actual.");
          setActionLoading(false);
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    }
  };

  const handleFinTraslado = async (option, description) => {
    setActionLoading(true); // Asegurarse de que el botón de acción muestre el estado de carga

    if (option === "recomendada") {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const distance = calculateDistance(
            latitude,
            longitude,
            traslado.destino_coordenadas.coordinates[1],
            traslado.destino_coordenadas.coordinates[0]
          );

          if (distance <= 100) {
            await enviarFinalizarTraslado(option, null, null); // Pasar null para coordenadas y descripción
          } else {
            message.warning(
              "Debes estar cerca del punto de destino para finalizar el traslado."
            );
            setActionLoading(false);
          }
        },
        (error) => {
          console.error("Error al obtener la ubicación:", error);
          message.error("No se pudo obtener la ubicación actual.");
          setActionLoading(false);
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      // Opciones "punto_destino" o "sin_senal"
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          await enviarFinalizarTraslado(
            option,
            { longitude, latitude },
            description
          );
        },
        (error) => {
          console.error("Error al obtener la ubicación:", error);
          message.error("No se pudo obtener la ubicación actual.");
          setActionLoading(false);
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    }
  };

  const enviarIniciarTraslado = async (option, coordinates, description) => {
    try {
      const payload = {
        option,
        coordinates:
          option !== "recomendada"
            ? {
                type: "Point",
                coordinates: [coordinates.longitude, coordinates.latitude],
              }
            : null, // Establecer en null si es "recomendada"
        description, // Asegúrate de enviar la descripción si existe
      };

      const response = await api.put(
        `/traslados/iniciar/${traslado.id}`,
        payload
      );
      updateTraslado(response.data.traslado);
      message.success("Traslado iniciado exitosamente.");
    } catch (error) {
      console.error("Error al iniciar el traslado:", error);
      message.error(
        error.response?.data?.error || "Error al iniciar el traslado."
      );
    } finally {
      setActionLoading(false);
    }
  };

  const enviarFinalizarTraslado = async (option, coordinates, description) => {
    try {
      const payload = {
        option,
        coordinates:
          option !== "recomendada"
            ? {
                type: "Point",
                coordinates: [coordinates.longitude, coordinates.latitude],
              }
            : null,
        description,
      };

      console.log("Payload enviado:", payload); // Para depuración

      const response = await api.put(
        `/traslados/finalizar/${traslado.id}`,
        payload
      );
      updateTraslado(response.data.traslado);
      message.success("Traslado finalizado exitosamente.");
    } catch (error) {
      console.error("Error al finalizar el traslado:", error);
      message.error(
        error.response?.data?.error || "Error al finalizar el traslado."
      );
    } finally {
      setActionLoading(false);
    }
  };

  // Función para renderizar los botones de acción
  const renderActionButtons = () => {
    switch (traslado.status) {
      case "Enterado":
        return (
          <Button
            type="primary"
            icon={<CheckCircleOutlined />}
            onClick={handleAceptarTraslado}
            style={{ width: "100%", height: 50, fontSize: 16 }}
            loading={actionLoading}
            aria-label="Aceptar Traslado"
          >
            Aceptar Traslado
          </Button>
        );
      case "Aceptado":
        return (
          <Button
            type="primary"
            icon={<CarOutlined />}
            onClick={handleEnCamino}
            style={{ width: "100%", height: 50, fontSize: 16 }}
            loading={actionLoading}
            aria-label="En Camino"
          >
            En Camino
          </Button>
        );
      case "En Camino":
        return (
          <Button
            type="primary"
            icon={<PauseOutlined />}
            onClick={handleEsperando}
            style={{ width: "100%", height: 50, fontSize: 16 }}
            loading={actionLoading}
            aria-label="Esperando"
          >
            Esperando
          </Button>
        );
      case "Esperando":
        return (
          <>
            <Button
              type="primary"
              icon={<PlayCircleOutlined />}
              onClick={() => handleIniciarTraslado()}
              disabled={!driverLocation}
              style={{
                width: "100%",
                height: 50,
                fontSize: 16,
                marginBottom: 10,
              }}
              loading={actionLoading}
              aria-label="Iniciar Traslado"
              title={
                !driverLocation ? "Debes obtener tu ubicación para iniciar" : ""
              }
            >
              Iniciar
            </Button>
            <Button
              type="default"
              icon={<ExclamationCircleOutlined />}
              onClick={() => handleProblemTraslado("iniciar")}
              style={{ width: "100%", height: 50, fontSize: 16 }}
              disabled={!driverLocation}
              aria-label="Tengo un problema al iniciar"
              title={
                !driverLocation
                  ? "Debes obtener tu ubicación para reportar un problema"
                  : ""
              }
            >
              Tengo un problema
            </Button>
          </>
        );
      case "Iniciado":
        return (
          <>
            <Button
              type="primary"
              icon={<FlagOutlined />}
              onClick={() => handleFinalizarTraslado()}
              disabled={!driverLocation}
              style={{
                width: "100%",
                height: 50,
                fontSize: 16,
                marginBottom: 10,
              }}
              loading={actionLoading}
              aria-label="Finalizar Traslado"
              title={
                !driverLocation
                  ? "Debes obtener tu ubicación para finalizar"
                  : ""
              }
            >
              Finalizar
            </Button>
            <Button
              type="default"
              icon={<ExclamationCircleOutlined />}
              onClick={() => handleProblemTraslado("finalizar")}
              style={{ width: "100%", height: 50, fontSize: 16 }}
              disabled={!driverLocation}
              aria-label="Tengo un problema al finalizar"
              title={
                !driverLocation
                  ? "Debes obtener tu ubicación para reportar un problema"
                  : ""
              }
            >
              Tengo un problema
            </Button>
          </>
        );
      case "Finalizado":
        return (
          <Text type="success" style={{ fontSize: 18 }}>
            Traslado Finalizado
          </Text>
        );
      default:
        return null;
    }
  };

  // Memorizar las coordenadas de origen y destino usando encadenamiento opcional
  const origin = useMemo(() => {
    const coords = traslado?.origen_coordenadas?.coordinates;
    return coords
      ? {
          latitude: coords[1],
          longitude: coords[0],
        }
      : null;
  }, [traslado]);

  const destination = useMemo(() => {
    const coords = traslado?.destino_coordenadas?.coordinates;
    return coords
      ? {
          latitude: coords[1],
          longitude: coords[0],
        }
      : null;
  }, [traslado]);

  if (loading || !traslado || !origin || !destination) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 20,
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      {/* Encabezado con botón de volver */}
      <div style={{ padding: 20, flexShrink: 0 }}>
        <Button
          icon={<LeftOutlined />}
          onClick={handleBack}
          style={{ width: "100%", height: 50, fontSize: 16 }}
          block
          aria-label="Volver a la Lista"
        >
          Volver a la Lista
        </Button>
      </div>

      {/* Información del traslado */}
      <div style={{ padding: "0 20px 10px 20px", flexShrink: 0 }}>
        <Card
          bordered={false}
          style={{
            backgroundColor: "#ffffff",
            borderRadius: 8,
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography.Title level={4} style={{ display: "inline" }}>
            <Tag color={getStatusColor(traslado.status)}>{traslado.status}</Tag>
            {/* Agregar Tags para inicio_status y fin_status */}
            {traslado.inicio_status && (
              <Tag
                color={getStatusColor(traslado.inicio_status)}
                style={{ marginLeft: 8 }}
              >
                {traslado.inicio_status}
              </Tag>
            )}
            {traslado.fin_status && (
              <Tag
                color={getStatusColor(traslado.fin_status)}
                style={{ marginLeft: 8 }}
              >
                {traslado.fin_status}
              </Tag>
            )}
          </Typography.Title>
          <div style={{ marginTop: 10 }}>
            <Typography.Text style={{ fontSize: 16 }}>
              <EnvironmentOutlined /> De: {traslado.origen_direccion} -{" "}
              {traslado.origen_ciudad}
            </Typography.Text>
            <br />
            <Typography.Text style={{ fontSize: 16 }}>
              <EnvironmentOutlined /> A: {traslado.destino_direccion} -{" "}
              {traslado.destino_ciudad}
            </Typography.Text>
            <br />
            <Typography.Text style={{ fontSize: 16 }}>
              <ClockCircleOutlined /> Hora Recogida:{" "}
              {traslado.hora_recogida.slice(0, 5)}
            </Typography.Text>
            <br />
            <Typography.Text style={{ fontSize: 16 }}>
              <Text strong>Paciente:</Text> {traslado.nombre_paciente}
            </Typography.Text>
            {traslado.point && (
              <>
                <br />
                <Typography.Text style={{ fontSize: 16 }}>
                  <Text strong>Punto Alternativo:</Text>{" "}
                  {traslado.point.description}
                </Typography.Text>
              </>
            )}
          </div>
        </Card>
      </div>

      {/* Mapa */}
      <div style={{ flex: 1, position: "relative" }}>
        <MapComponent
          origin={origin}
          destination={destination}
          driverLocation={driverLocation}
        />

        {geolocationError && (
          <Alert
            message="Error de Geolocalización"
            description={geolocationError}
            type="error"
            showIcon
            style={{
              position: "absolute",
              top: 10,
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1000,
            }}
          />
        )}
      </div>

      {/* Botones de acción */}
      <div style={{ padding: 20, backgroundColor: "#ffffff", flexShrink: 0 }}>
        {renderActionButtons()}
      </div>

      {/* Modal para opciones: Renderizar solo cuando el modal está visible */}
      {isModalVisible && currentAction && (
        <OptionModal
          open={isModalVisible}
          onCancel={() => {
            setIsModalVisible(false);
            setCurrentAction(null);
          }}
          onSubmit={handleModalSubmit}
          title={getModalTitle()}
          action="problema"
          parentAction={currentAction} // Pasar el prop 'parentAction'
        />
      )}
    </div>
  );
};

export default TrasladoMapView;
