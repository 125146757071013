// src/SettingsContext.js
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import PropTypes from "prop-types";

// Crear el contexto
const SettingsContext = createContext();

// Proveedor del contexto
export const SettingsProvider = ({ children }) => {
  const [enableHighAccuracy, setEnableHighAccuracy] = useState(false);

  // Cargar configuración desde localStorage al inicializar
  useEffect(() => {
    const storedAccuracy = localStorage.getItem("enableHighAccuracy");
    if (storedAccuracy !== null) {
      setEnableHighAccuracy(JSON.parse(storedAccuracy));
    }
  }, []);

  // Guardar configuración en localStorage cuando cambie
  useEffect(() => {
    localStorage.setItem(
      "enableHighAccuracy",
      JSON.stringify(enableHighAccuracy)
    );
  }, [enableHighAccuracy]);

  // Memoizar el valor del contexto para evitar recreaciones innecesarias
  const contextValue = useMemo(
    () => ({ enableHighAccuracy, setEnableHighAccuracy }),
    [enableHighAccuracy]
  );

  return (
    <SettingsContext.Provider value={contextValue}>
      {children}
    </SettingsContext.Provider>
  );
};

// Validar las props del proveedor
SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Hook para usar el contexto
export const useSettings = () => useContext(SettingsContext);
